import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkIcon from "@mui/icons-material/Link";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LaptopIcon from "@mui/icons-material/Laptop";
import {
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import QRCode from "react-qr-code";
import { useLinkFirstInvite } from "src/features/useLinkFirstInvite";
import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import {
  INVITE_STYLE,
  InviteStyle,
  isCalendarFirst,
  isEmailFirst,
  isLinkFirst,
  isWebinar,
} from "src/meetingTypes/invite/props";
import { MeetingDefinition } from "src/types";
import { TemplateEditSelectableCard } from "./TemplateEditSelectableCard";
import PrimaryButton from "./buttons/PrimaryButton";
import { useWebinars } from "src/features/useWebinars";
import { ChangeMeetingTemplateType } from "./dialogs/ChangeMeetingTemplateType";
import { useUpdateMeetingTemplateType } from "src/mutations/useUpdateMeetingTemplateType";

export const TemplateEditSettings = () => {
  const theme = useTheme();
  const { control, watch, getValues } = useFormContext<MeetingDefinition>();
  const { addGeneralNotification, addError } = useGeneralNotifications();
  const qrCodeRef = useRef(null);
  const linkFirstInviteEnabled = useLinkFirstInvite();
  const webinarsEnabled = useWebinars();
  const updateMeetingTemplateType = useUpdateMeetingTemplateType();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [toMeetingType, setToMeetingType] = useState<InviteStyle | null>(null);

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Template Settings
        </Typography>
        <Typography>
          Set up which style of template you will be using and enable 1-click
          scheduling.
        </Typography>
      </Box>
      <Typography variant="h5" fontWeight="bold">
        Invite Style
      </Typography>
      <Typography sx={{ mb: 3 }}>
        Select which Kronologic product you wish to use for this template.
      </Typography>

      <Controller
        control={control}
        name="inviteStyle"
        render={({ field }) => (
          <>
            <ChangeMeetingTemplateType
              open={modalOpen}
              onSubmit={async () => {
                const id = getValues("id");
                if (id < 1) {
                  return;
                }

                if (toMeetingType !== null) {
                  await updateMeetingTemplateType(id, toMeetingType);
                  field.onChange(toMeetingType);
                }
                setModalOpen(false);
                setToMeetingType(null);
              }}
              onClose={() => {
                setModalOpen(false);
                setToMeetingType(null);
              }}
            />
            <Stack
              sx={{ flexDirection: "row", gap: 5, mb: 6, minHeight: "150px" }}
            >
              <Box sx={{ flex: "1 1 0", maxWidth: "320px" }}>
                <TemplateEditSelectableCard
                  selected={isCalendarFirst(field.value)}
                  onClick={async () => {
                    if (isCalendarFirst(field.value)) {
                      return;
                    }

                    if (isWebinar(field.value)) {
                      setToMeetingType(INVITE_STYLE.CALENDAR_FIRST);
                      setModalOpen(true);
                      return;
                    }

                    await updateMeetingTemplateType(
                      getValues("id"),
                      INVITE_STYLE.CALENDAR_FIRST,
                    );
                    field.onChange(INVITE_STYLE.CALENDAR_FIRST);
                  }}
                >
                  <Stack
                    sx={{
                      mb: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      color: "primary.dark",
                      gap: 2,
                      cursor: "pointer",
                    }}
                  >
                    <CalendarMonthIcon />
                    <Typography variant="h5" fontWeight="bold">
                      Kronologic Invite
                    </Typography>
                  </Stack>
                  <Typography>
                    Calendar invite and email are sent at the same time.
                    <br />
                    <br />
                    Best for: Contact Us, Demo/Pricing Requests, Event Booth
                    Leads, Renewals, Direct Mail.{" "}
                    <Link href="https://vimeo.com/844744016" target="_blank">
                      Learn More
                    </Link>
                  </Typography>
                </TemplateEditSelectableCard>
              </Box>

              <Box sx={{ flex: "1 1 0", maxWidth: "320px" }}>
                <TemplateEditSelectableCard
                  selected={isEmailFirst(field.value)}
                  onClick={async () => {
                    if (isEmailFirst(field.value)) {
                      return;
                    }

                    if (isWebinar(field.value)) {
                      setToMeetingType(INVITE_STYLE.CUSTOM_INVITE);
                      setModalOpen(true);
                      return;
                    }

                    await updateMeetingTemplateType(
                      getValues("id"),
                      INVITE_STYLE.CUSTOM_INVITE,
                    );
                    field.onChange(INVITE_STYLE.CUSTOM_INVITE);
                  }}
                >
                  <Stack
                    sx={{
                      mb: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      color: "primary.dark",
                      gap: 2,
                    }}
                  >
                    <MailOutlineIcon />
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      color="primary.dark"
                    >
                      Kronologic Email
                    </Typography>
                  </Stack>
                  <Typography>
                    Email sent first, calendar invite sent after acceptance.
                    <br />
                    <br />
                    Best for: Content Downloads, Webinars, Event Opt-in Lists,
                    ABM Campaigns, Nurture Campaigns.{" "}
                    <Link href="https://vimeo.com/844743754" target="_blank">
                      Learn More
                    </Link>
                  </Typography>
                </TemplateEditSelectableCard>
              </Box>

              {linkFirstInviteEnabled && (
                <Box sx={{ flex: "1 1 0", maxWidth: "320px" }}>
                  <TemplateEditSelectableCard
                    selected={isLinkFirst(field.value)}
                    onClick={async () => {
                      if (isLinkFirst(field.value)) {
                        return;
                      }

                      if (isWebinar(field.value)) {
                        setToMeetingType(INVITE_STYLE.LINK_FIRST);
                        setModalOpen(true);
                        return;
                      }

                      await updateMeetingTemplateType(
                        getValues("id"),
                        INVITE_STYLE.LINK_FIRST,
                      );
                      field.onChange(INVITE_STYLE.LINK_FIRST);
                    }}
                  >
                    <Stack
                      sx={{
                        mb: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        color: "primary.dark",
                        gap: 2,
                      }}
                    >
                      <LinkIcon />
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color="primary.dark"
                      >
                        Kronologic Link
                      </Typography>
                    </Stack>
                    <Typography>
                      Person uses the link from email or website to schedule a
                      meeting with you in a few clicks.
                      <br />
                      <br />
                      Best for: Embedding in external content such as emails and
                      websites.
                      <br />
                      <br />
                      Note: meetings of this type cannot be launched by
                      Kronologic user, they can only be initiated by external
                      contact who uses the link to schedule a meeting.{" "}
                      <Link href="https://vimeo.com/844743627" target="_blank">
                        Learn More
                      </Link>
                    </Typography>
                  </TemplateEditSelectableCard>
                </Box>
              )}
              {webinarsEnabled && (
                <Box sx={{ flex: "1 1 0", maxWidth: "320px" }}>
                  <TemplateEditSelectableCard
                    selected={isWebinar(field.value)}
                    onClick={() => {
                      if (isWebinar(field.value)) {
                        return;
                      }

                      setToMeetingType(INVITE_STYLE.WEBINAR);
                      setModalOpen(true);
                    }}
                  >
                    <Stack
                      sx={{
                        mb: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        color: "primary.dark",
                        gap: 2,
                      }}
                    >
                      <LaptopIcon />
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color="primary.dark"
                      >
                        Kronologic Webinar
                      </Typography>
                    </Stack>
                    <Typography>
                      Invite guests to join a pre-scheduled webinar & follow up
                      with them
                    </Typography>
                  </TemplateEditSelectableCard>
                </Box>
              )}
            </Stack>
          </>
        )}
      />
      {!isWebinar(watch("inviteStyle")) && (
        <>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Typography variant="h5" fontWeight="bold">
              1 Click Scheduling
            </Typography>
          </Stack>
          <Typography sx={{ mb: 3 }}>
            Enabling one click scheduling will generate a link and QR code you
            can share with guests so that they can schedule a meeting.
          </Typography>
          <Controller
            control={control}
            name="schedulingUrls"
            render={({ field }) => (
              <>
                {field.value?.[0] && (
                  <Stack sx={{ flexDirection: "row", gap: 10 }}>
                    <Box>
                      <Typography sx={{ mb: 2 }} variant="h6" fontWeight="bold">
                        Meeting Link
                      </Typography>

                      <Stack sx={{ flexDirection: "row", gap: 2 }}>
                        <a
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: 0,
                            backgroundColor: "#F8FBFF",
                            padding: "10px 20px",
                            fontWeight: "bold",
                          }}
                          href={field.value[0].url}
                        >
                          {field.value[0].url}
                        </a>

                        <IconButton
                          sx={{ color: theme.palette.primary.main }}
                          onClick={async () => {
                            try {
                              if (!field.value?.[0]) {
                                return;
                              }
                              await navigator.clipboard.writeText(
                                field.value[0].url,
                              );
                              addGeneralNotification(
                                "1 Click link copied to clipboard!",
                              );
                            } catch (err) {
                              addError(
                                "Failed to copy the 1 click link to clipboard",
                              );
                              console.error("Failed to copy: ", err);
                            }
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Stack>
                    </Box>

                    <Box>
                      <Typography sx={{ mb: 2 }} variant="h6" fontWeight="bold">
                        QR Code
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        <QRCode
                          ref={qrCodeRef}
                          size={128}
                          id="qrcode"
                          value={field.value[0].url}
                        />
                        <canvas hidden width={128} height={128} id="canvas" />
                      </Box>
                      <PrimaryButton
                        onClick={() => {
                          const svg: any = document.getElementById("qrcode");
                          const canvas: any = document.getElementById("canvas");

                          const ctx = canvas.getContext("2d");
                          const data = new XMLSerializer().serializeToString(
                            svg,
                          );
                          const DOMURL =
                            window.URL || window.webkitURL || window;

                          const img = new Image();
                          const svgBlob = new Blob([data], {
                            type: "image/svg+xml;charset=utf-8",
                          });

                          const u = DOMURL.createObjectURL(svgBlob);

                          img.onload = function () {
                            ctx.drawImage(img, 0, 0);
                            DOMURL.revokeObjectURL(u);

                            const imgURI = canvas
                              .toDataURL("image/png")
                              .replace("image/png", "image/octet-stream");

                            const element = document.createElement("a");
                            element.download = "QRCode.png";
                            element.href = imgURI;
                            element.click();
                            element.remove();
                          };

                          img.src = u;
                        }}
                      >
                        Download QR Code
                      </PrimaryButton>
                    </Box>
                  </Stack>
                )}
              </>
            )}
          />
        </>
      )}
    </Box>
  );
};
