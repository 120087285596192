import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useOpenAiIntegration } from "src/features";
import { getDynamicVariableMappings } from "src/meetingTypes/useDynamicVariableMappings";
import { MeetingDefinition } from "src/types";
import { TipTapPreview } from "./RichText";
import TemplateAIModal from "./TemplateAIModal";
import SecondaryButton from "./buttons/SecondaryButton";
import TemplateInputWithMergeFields from "./inputs/TemplateInputWithMergeFields";
import { isWebinar } from "src/meetingTypes/invite/props";

function CalendarInvite() {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");
  const [aiModalOpen, setAIModalOpen] = useState<boolean>(false);
  const openAIEnabled = useOpenAiIntegration();

  const {
    control,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("inviteTemplates.0.title"),
  );
  const previewLocation = getDynamicVariableMappings(
    getValues("inviteTemplates.0.location"),
  );
  const previewNotes = getDynamicVariableMappings(
    getValues("inviteTemplates.0.body"),
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      {selectedTab === "draft" && (
        <>
          <Controller
            control={control}
            name="inviteTemplates.0.title"
            rules={{
              required: {
                value: true,
                message: "Invite Title is required",
              },
              minLength: {
                value: 5,
                message: "Invite Title must be at least 5 characters.",
              },
            }}
            render={({ field }) => (
              <TemplateInputWithMergeFields
                label="Invite Title"
                value={field.value}
                onChange={field.onChange}
                placeholder={
                  isWebinar(watch("inviteStyle"))
                    ? "Enter your event title"
                    : undefined
                }
              />
            )}
          />

          {errors.inviteTemplates?.[0]?.title && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.title.message}
            </Box>
          )}

          <Controller
            control={control}
            name="inviteTemplates.0.location"
            rules={{
              required: {
                value: true,
                message: "Invite Location is required",
              },
            }}
            render={({ field }) => (
              <TemplateInputWithMergeFields
                label="Invite Location"
                value={field.value}
                onChange={field.onChange}
                placeholder={
                  isWebinar(watch("inviteStyle"))
                    ? "Enter your event location"
                    : undefined
                }
              />
            )}
          />

          {errors.inviteTemplates?.[0]?.location && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.location.message}
            </Box>
          )}

          <Controller
            control={control}
            name="inviteTemplates.0.body"
            rules={{
              required: {
                value: true,
                message: "Invite Message is required",
              },
              validate: (value) =>
                value.trim().length > 0 || "Invite Message is required",
            }}
            render={({ field }) => (
              <>
                <TemplateAIModal
                  open={aiModalOpen}
                  onClose={() => {
                    setAIModalOpen(false);
                  }}
                  onAIResponse={(text: string) => {
                    field.onChange(text.trimStart());
                  }}
                />

                <TemplateInputWithMergeFields
                  label="Invite Message"
                  value={field.value}
                  onChange={field.onChange}
                  multiline
                  rows={10}
                  placeholder={
                    isWebinar(watch("inviteStyle"))
                      ? "Enter your event details"
                      : undefined
                  }
                />
              </>
            )}
          />
          {errors.inviteTemplates?.[0]?.body && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.body.message}
            </Box>
          )}
        </>
      )}
      {selectedTab === "preview" && (
        <>
          <Typography sx={{ mb: 1 }} variant="h6" fontWeight="bold">
            {previewTitle}
          </Typography>
          <Typography sx={{ mb: 4 }} color="primary">
            {previewLocation}
          </Typography>
          <Typography component="div">
            <TipTapPreview content={previewNotes} />
          </Typography>
        </>
      )}
      {openAIEnabled && (
        <Box width="25%">
          <SecondaryButton
            onClick={() => {
              setAIModalOpen(true);
            }}
          >
            Use AI to write your invite
          </SecondaryButton>
        </Box>
      )}
    </>
  );
}

export default CalendarInvite;
