import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";

export function ChangeMeetingTemplateType({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Type Change</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Changing from the current meeting type to this meeting type will
            reset all meeting details and any configurations made in the current
            meeting type will be undone.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onSubmit}>Continue</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
