import React, { useCallback, useRef, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import MergeFieldsSelect from "./EmailEditor/MergeFieldsSelect";

function TemplateInputWithMergeFields({
  label,
  value,
  onChange,
  multiline = false,
  rows = 1,
  placeholder = "",
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
}) {
  const inputRef = useRef<HTMLInputElement | null>();
  // show dropdowns when mousing over field
  const [mouseInBounds, setMouseInBounds] = useState<boolean>(false);
  // show dropdowns when field is focused
  const [focused, setFocused] = useState<boolean>(false);

  const [wasFocused, setWasFocused] = useState<boolean>(false);

  const [cursorStart, setCursorStart] = useState<number>(0);
  const [cursorEnd, setCursorEnd] = useState<number>(0);

  const focusInputElement = (cursorPos: number): void => {
    // this feels hacky but is generally how people do it
    // for some reason, you cannot synchronusly change a field
    // then focus it
    setTimeout(() => {
      if (!inputRef || !inputRef.current) {
        return;
      }
      inputRef.current.focus();
      inputRef.current.setSelectionRange(cursorPos, cursorPos);

      setCursorStart(cursorPos);
      setCursorEnd(cursorPos);
    }, 0);
  };

  // need to insert the selected dynamic variable at the cursor location
  // and ensure the input remains foused with the cursor just after the
  // inserted dynamic variable
  const onSelect = useCallback(
    (dynamicVariable: string) => {
      if (!inputRef || !inputRef.current) {
        return;
      }

      const { current: input } = inputRef;
      const currentValue = input.value;

      // if this field isn't currently focused, just append the selected
      // field to the end of the text
      if (!wasFocused) {
        const updatedValue = `${currentValue} ${dynamicVariable}`;
        onChange(updatedValue);

        // focus at end
        focusInputElement(updatedValue.length);
        return;
      }

      let start = cursorStart;
      let end = cursorEnd;

      if (start > currentValue.length) {
        start = currentValue.length;
      }

      if (end > currentValue.length) {
        end = currentValue.length;
      }

      // if the user has a range highlighted, we want to replace that text
      // with the incoming value
      const spliceStart = currentValue.slice(0, start);
      const spliceEnd = currentValue.slice(end);

      const updatedValue = `${spliceStart}${dynamicVariable}${spliceEnd}`;
      onChange(updatedValue);

      focusInputElement(spliceStart.length + dynamicVariable.length);
    },
    [cursorEnd, cursorStart, onChange, wasFocused],
  );

  const trackCursor = useCallback(() => {
    if (!inputRef || !inputRef.current) {
      return;
    }

    const {
      current: { selectionStart, selectionEnd },
    } = inputRef;

    if (selectionStart === null || selectionEnd === null) {
      return;
    }

    setCursorStart(selectionStart);
    setCursorEnd(selectionEnd);
  }, []);

  return (
    <TextField
      placeholder={placeholder}
      inputRef={inputRef}
      label={label}
      value={value}
      onMouseOutCapture={trackCursor}
      onMouseUp={trackCursor}
      onMouseDown={trackCursor}
      onKeyUp={trackCursor}
      onKeyDown={trackCursor}
      onClick={() => {
        trackCursor();
        setWasFocused(true);
      }}
      onChange={(event) => {
        trackCursor();
        onChange(event.target.value);
        setWasFocused(true);
      }}
      onMouseEnter={() => {
        setMouseInBounds(true);
      }}
      onMouseLeave={() => {
        setMouseInBounds(false);
      }}
      onFocus={() => {
        trackCursor();
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      InputProps={{
        endAdornment:
          focused || mouseInBounds ? (
            <InputAdornment
              position="end"
              sx={{
                marginBottom: multiline ? "auto" : undefined,
                padding: multiline ? "10px" : undefined,
              }}
            >
              <MergeFieldsSelect onSelect={onSelect} />
            </InputAdornment>
          ) : null,
      }}
      size="small"
      sx={{ width: "100%", mb: 2 }}
      multiline={multiline}
      rows={rows}
    />
  );
}

export default TemplateInputWithMergeFields;
