import { getUserToken } from "src/utils/jwtToken";
import { useSWRConfig } from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";
import { InviteStyle } from "src/meetingTypes/invite/props";

export function useUpdateMeetingTemplateType() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return async (id: number, newType: InviteStyle): Promise<void> => {
    service
      .patch(`/api/meetings/definition/${id}/${newType}`)
      .set(headers)
      .send()
      .then(tokenRefreshHandler)
      .then((res: Response) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate contact queries and force refresh.
        Array.from(keys)
          .filter(
            (r: any) =>
              r.includes(`/api/meetings/definition/list`) ||
              r.includes(`/api/meetings/definition/${id}`),
          )
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
  };
}
